<header class="it-header-wrapper it-header-sticky" data-bs-toggle="sticky" data-bs-position-type="fixed"
  data-bs-sticky-class-name="is-sticky" data-bs-target="#header-nav-wrapper">
  <div class="it-header-slim-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="it-header-slim-wrapper-content">
            <a [href]="navBrandLink" class="d-lg-block navbar-brand">{{ navBrandTitle }}</a>
            <div class="it-header-slim-right-zone">
              <!-- <div class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="visually-hidden">{{ 'SELECTED_LANGUAGE' | translate }}</span>
                  <span>{{ selectedLanguage}}</span>
                  <svg class="icon" aria-hidden="true">
                    <use href="bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
                  </svg>
                </a>
                <div class="dropdown-menu">
                  <div class="row">
                    <div class="col-12">
                      <div class="link-list-wrapper">
                        <ul class="link-list">
                          <li *ngFor="let lang of languages">
                            <a class="dropdown-item list-item" [href]="lang.url"
                              (click)="changeLanguage(lang.langcode)"><span>{{ lang.label }}</span></a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <a class="btn btn-primary btn-icon btn-full" href="https://myarea.comune.padova.it/myportal/C_G224_RV/area-privata/login" title="Accedi all'area personale"
                aria-label="Accedi all'area personale">
                <span class="rounded-icon">
                  <svg class="icon icon-primary" aria-hidden="true" aria-label="Accedi all'area personale">
                    <use href="bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                  </svg>
                </span>
                <span class="d-none d-lg-block">Accedi all'area personale</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="it-nav-wrapper">
    <div class="it-header-center-wrapper theme-light">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="it-header-center-content-wrapper">
              <div class="it-brand-wrapper">
                <a routerLink="/" title="Comune di Padova">
                  <img src="/assets/images/logo.svg" class="icon" alt="padova net" title="Comune di Padova">
                  <div class="it-brand-text">
                    <div class="it-brand-title">{{brandTitle}}</div>
                    <div class="it-brand-tagline d-none d-md-block"> Padovanet</div>
                  </div>
                </a>
              </div>
              <div class="it-right-zone">
                <div class="it-socials d-none d-lg-flex">
                  <span><b>{{"FOLLOW_US" | translate}}</b></span>
                  <ul>
                    <li *ngFor="let social of socialNetworks">
                      <a [href]="social.url" [target]="social.target">
                        <svg class="icon icon-sm align-top" [attr.aria-label]="social.aria">
                          <use [attr.href]="'/bootstrap-italia/dist/svg/sprites.svg#'+social.icon"></use>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="it-search-wrapper">
                  <span class="d-none d-md-block">{{"SEARCH" | translate}}</span>
                  <a role="button" class="search-link rounded-icon" data-bs-toggle="modal"
                    data-bs-target="#search-modal" aria-label="Cerca nel sito" data-focus-mouse="false">
                    <svg class="icon" aria-label="Cerca nel sito">
                      <use href="/bootstrap-italia/dist/svg/sprites.svg#it-search"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="it-header-navbar-wrapper" id="header-nav-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg has-megamenu" aria-label="Menù principale"> <button
                class="custom-navbar-toggler" type="button" aria-controls="main-menu" aria-expanded="false"
                aria-label="Attiva/disattiva la navigazione" title="Attiva/disattiva la navigazione"
                data-bs-target="#main-menu" data-bs-toggle="navbarcollapsible"> <svg class="icon"
                  aria-label="Attiva/disattiva la navigazione">
                  <use href="bootstrap-italia/dist/svg/sprites.svg#it-burger"></use>
                </svg>
              </button>
              <div class="navbar-collapsable" id="main-menu">
                <div class="overlay" style="display: none;"></div>
                <div class="close-div">
                  <button class="btn close-menu" type="button">
                    <span class="visually-hidden">nascondi la navigazione</span>
                    <svg class="icon" aria-label="nascondi la navigazione">
                      <use href="/bootstrap-italia/dist/svg/sprites.svg#it-close-big"></use>
                    </svg>
                  </button>
                </div>
                <div class="menu-wrapper">
                  <a routerLink="/" title="Comune di Padova" class="logo-hamburger">
                    <img class="icon " title="Comune di Padova" alt="Comune di Padova" src="/assets/images/logo.svg"
                      style="width: auto !important;">
                    <div class="it-brand-text">
                      <div class="it-brand-title">Comune di Padova</div>
                    </div>
                  </a>
                  <ul class="navbar-nav" data-element="main-navigation">
                    <li class="nav-item" *ngFor="let menu of menuLinks">
                      <a class="main-nav-link nav-link text-truncate" [routerLink]="menu.alias" [title]="menu.title"
                        [attr.data-element]="menu.description"> <span>{{menu.title}}</span> </a>
                    </li>
                  </ul>
                  <ul class="navbar-nav navbar-secondary" aria-label="Menù secondario">
                    <li class="nav-item">
                      <a class="nav-link text-truncate" href="/argomenti" data-element="all-topics">
                        <span class="fw-bold">Tutti gli argomenti...</span> </a>
                    </li>
                  </ul>
                  <div class="it-socials "> <span>Seguici su</span>
                    <ul>
                      <li *ngFor="let social of socialNetworks">
                        <a [href]="social.url" [target]="social.target">
                          <svg class="icon icon-sm align-top" [attr.aria-label]="social.aria">
                            <use [attr.href]="'/bootstrap-italia/dist/svg/sprites.svg#'+social.icon"></use>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>