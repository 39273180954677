import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse, Document } from '../models/searchresult.model';

@Injectable({
  providedIn: 'root'
})
export class DrupalService {

  private API_URL = environment.config.backendUrl;
  searchResults = signal<any[]>([]);

  // Step 1: Add a cache storage
  private contentCache: { [key: string]: any } = {};
  private navigationCache: { [lang: string]: any } = {};

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  getContentByPath(path: string = '', langcode: string = 'it'): Observable<any> {
    const cacheKey = `${path}_${langcode}`;

    // Check if cache exists
    if (this.contentCache[cacheKey]) {
      return of(this.contentCache[cacheKey]);
    }

    // Fetch data, cache it, then return it
    return this.http.get(`${this.API_URL}/api/entity?path=${path}`)
      .pipe(
        tap((data: any) => {
          // Check if data is valid before caching, you might need to adjust validation logic
          if (data && Object.keys(data).length > 0) {
            this.contentCache[cacheKey] = data;
          }
        }),
        catchError(error => {
          console.error('Error fetching data:', error);
          return of({}); // Return an empty object to avoid breaking the app
        })
      )
  }


  getFromES(path: string = '', langcode: string = 'it') {
    return this.http.get(`${this.API_URL}/${langcode}/api/entity/${path}`).
      pipe(
        tap((data: any) => {
          this.contentCache[path] = data;
        })
      );
  }

  //create preview function using cookies
  getPreview(path: string = '') {    //get preview
    return this.http.get(`${this.API_URL}${path}`, { withCredentials: true });
  }

  getFaqs(): any {
    return this.http.get(`${this.API_URL}/api/faqs?fields[faq]=title,faq_body`)
      .pipe(
        tap((data: any) => {
          this.contentCache['faqs'] = data;
        })
      );
  }

  getNavigation(lang: string = 'it') {
    if (this.navigationCache[lang]) {
      return of(this.navigationCache[lang]);
    }

    return this.http.get(`${this.API_URL}/api/menu_items/main`)
      .pipe(
        tap((data: any) => {
          this.navigationCache[lang] = data;
        })
      );
  }

  currentUser(uuid: string = '') {
    return this.http.get(`${this.API_URL}/user/current-user?uuid=${uuid}`);
  }

  getAllByType($langcode: string = 'it', type: string = '', include: string = '', page: number = 0, limit: number = 6, sort: string = 'changed') {
    return this.http.get(`${this.API_URL}/api/${type}?filter[status][value]=1&include=${include}&jsonapi_include=1&sort=-${sort}&page[offset]=${page}&page[limit]=${limit}`);
  }

  ESQuerySearch(type: string = '', query: {}) {
    return this.http.post(`${this.API_URL}/api/search/${type}`, query);
  }



  getConfig() {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(`${this.API_URL}/api/config_pages/site_config?include=field_logo,field_logos`).subscribe((response: any) => {
          resolve(response)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  getTaxonomyVocabulary(vocabulary: string = '',) {
    return this.http.get(`${this.API_URL}/api/taxonomy_term/${vocabulary}?fields[taxonomy_term--eventi]=drupal_internal__tid,name`);
  }

  getPlacesFromES(limit: number = 999) {
    return this.http.post(`${this.API_URL}/api/search/place`, {
      "size": limit,
      "_source": ["data.title", "data.path.alias", "data.place_address", "data.place_cap", "data.place_gps_latitude_float", "data.place_gps_longitude_float"],
    })
  }

  getNewsByTagId<News>(params: any): Observable<ApiResponse<News>> {
    return this.http.get(`${this.API_URL}/api/news?filter[news_tags.id]=${params.identifier}&filter[status][value]=1&fields[news]=title,moderation_state,news_description,path,news_type,news_publication_date,news_end_date&include=news_type&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractNewsInfo<News>(response)),
    );
  }

  /*   getNewsByTagId1(identifier: string, page: number = 0, limit: number = 3): Observable<{ news: News[], paginationInfo: any }> {
      return this.http.get(`${this.API_URL}/api/news?filter[news_tags.id]=${identifier}&fields[news]=title,moderation_state,news_description,path,news_type,news_publication_date,news_end_date&include=news_type&page[offset]=${page}&page[limit]=${limit}`).pipe(
        map((response: any) => this.extractNewsInfo(response)),
      );
    } */
  getServicesByTagId<Service>(params: any): Observable<ApiResponse<Service>> {
    return this.http.get(`${this.API_URL}/api/services?filter[service_tags.id]=${params.identifier}&filter[status][value]=1&fields[service]=title,moderation_state,service_short_description,path,service_category&include=service_category&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractServicesInfo(response)),
    );
  }
  getDocumentsByTagId<Document>(params: any): Observable<any> {
    return this.http.get(`${this.API_URL}/api/public_documents?filter[public_document_tags.id]=${params.identifier}&filter[status][value]=1&fields[public_document]=title,moderation_state,public_document_body,public_document_type,public_document_short_desc,path&include=public_document_type&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractDocumentsInfo(response)),
    );
  }
  getOrgUnitByTagId<OrgUnit>(params: any): Observable<ApiResponse<OrgUnit>> {
    return this.http.get(`${this.API_URL}/api/org_units?filter[org_unit_tags.id]=${params.identifier}&filter[status][value]=1&fields[org_unit]=title,moderation_state,org_unit_short_description,path,org_unit_tags&include=org_unit_tags&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractOrgUnitInfo(response)),
    );
  }
  getPlacesByTagId<Place>(params: any): Observable<ApiResponse<Place>> {
    return this.http.get(`${this.API_URL}/api/places?filter[place_tags.id]=${params.identifier}&filter[status][value]=1&fields[place]=title,moderation_state,place_short_description,path,place_tags&include=place_tags&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractPlaceInfo(response)),
    );
  }
  getEventsByTagId<Place>(params: any): Observable<ApiResponse<Place>> {
    return this.http.get(`${this.API_URL}/api/events?filter[event_tags.id]=${params.identifier}&filter[status][value]=1&fields[event]=title,moderation_state,status,event_short_description,path,event_date,event_tags&include=event_tags&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractEventsInfo(response)),
    );
  }

  getInformationsByTagId<Information>(params: any): Observable<ApiResponse<Information>> {
    return this.http.get(`${this.API_URL}/api/informations?filter[field_tags.id]=${params.identifier}&filter[status][value]=1&fields[information]=title,moderation_state,information_desc,path,field_tags&include=field_tags&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => this.extractInformationInfo(response)),
    );
  }

  // local information call
  /* getInformationsByTagId<Information>(params: any): Observable<ApiResponse<Information>> {
    return this.http.get(`${this.API_URL}/api/informations?filter[field_argomenti.id]=${params.identifier}&filter[status][value]=1&fields[information]=title,moderation_state,information_desc,path,field_argomenti&include=field_argomenti&page[offset]=${params.currentPage * params.displayedItems}&page[limit]=${params.displayedItems}`).pipe(
      map((response: any) => {
        return this.extractInformationInfo(response)
      }),
    );
  } */


  extractNewsInfo<News>(response: any): ApiResponse<News> {
    const news = response?.data.map((singleResult: any) => {
      return {
        type: "Notizia",
        title: singleResult?.title,
        description: singleResult?.news_description,
        publicationDate: singleResult?.news_publication_date
          ? this.datePipe.transform(singleResult?.news_publication_date, 'dd/MM/yyyy')
          : undefined,
        url: singleResult?.path?.alias,
        category: singleResult?.news_type?.name,
        image: singleResult.news_image?.field_media_image
          ? {
            url: singleResult?.news_image?.field_media_image?.uri?.url,
            alternativeText: singleResult?.news_image?.field_media_image?.meta?.alt
          }
          : null
      };
    }).filter((item: any) => item !== null) as News[]; // Rimuovi eventuali oggetti null

    return { data: news, paginationInfo: response?.meta };
  }


  extractDocumentsInfo(response: any): ApiResponse<Document> {
    const documents = response.data.map((singleResult: any) => {
      return {
        type: "Documento",
        title: singleResult?.title,
        description: singleResult?.public_document_short_desc,
        category: singleResult?.public_document_type[0]?.name,
        url: singleResult?.path?.alias,
      };

    }).filter((item: any) => item !== null) as Document[]; // Rimuovi eventuali oggetti null
    return { data: documents, paginationInfo: response?.meta };

  }
  extractOrgUnitInfo<OrgUnit>(response: any): ApiResponse<OrgUnit> {
    const orgUnit = response?.data.map((singleResult: any) => {
      return {
        type: "Unità",
        title: singleResult.title,
        description: singleResult.org_unit_short_description,
        url: singleResult?.path?.alias,
        category: singleResult?.org_unit_tags[0]?.name,
      };

    }).filter((item: any) => item !== null) as OrgUnit[]; // Rimuovi eventuali oggetti null
    return { data: orgUnit, paginationInfo: response?.meta };

  }
  extractPlaceInfo<Place>(response: any): ApiResponse<Place> {
    const place = response?.data.map((singleResult: any) => {
      return {
        type: "Luogo",
        title: singleResult.title,
        description: singleResult.place_short_description,
        url: singleResult?.path?.alias,
        category: singleResult?.place_tags[0]?.name,
        image: singleResult.place_image?.field_media_image
          ? {
            url: singleResult?.place_image?.field_media_image?.uri?.url,
            alternativeText: singleResult?.place_image?.field_media_image?.meta?.alt
          }
          : null
      };

    }).filter((item: any) => item !== null) as Place[]; // Rimuovi eventuali oggetti null
    return { data: place, paginationInfo: response?.meta };

  }
  extractInformationInfo<Information>(response: any): ApiResponse<Information> {
    const information = response?.data.map((singleResult: any) => {
      return {
        type: "Informazione",
        title: singleResult.title,
        description: singleResult.information_desc,
        url: singleResult?.path?.alias,
      };

    }).filter((item: any) => item !== null) as Information[]; // Rimuovi eventuali oggetti null
    return { data: information, paginationInfo: response?.meta };

  }
  extractServicesInfo<Service>(response: any): ApiResponse<Service> {
    const services = response?.data.map((singleResult: any) => {
      return {
        type: "Servizio",
        title: singleResult.title,
        description: singleResult.service_short_description,
        url: singleResult?.path?.alias,
        category: singleResult?.service_category[0]?.name,
      };

    }).filter((item: any) => item !== null) as Service[]; // Rimuovi eventuali oggetti null
    return { data: services, paginationInfo: response?.meta };

  }

  getNextUpcomingDate(eventDates: any[]): string | false {
    const now = new Date();
    const upcomingDates = eventDates
      .map(eventDate => {
        const eventDateValue = new Date(eventDate.value);
        const eventEndDateValue = new Date(eventDate.end_value);
        const currentDate = new Date();
        let resultDate;
        if (eventDateValue >= currentDate) {
          resultDate = eventDateValue;
        } else if (eventEndDateValue >= currentDate) {
          resultDate = currentDate;
        } else {
          resultDate = eventEndDateValue;
        }
        return resultDate
      })
      .filter(date => date >= now)
      .sort((a, b) => a.getTime() - b.getTime());

    return upcomingDates.length > 0 ? upcomingDates[0].toISOString() : false;
  }

  extractEventsInfo<Event>(response: any): ApiResponse<Event> {
    const events = response?.data.map((singleResult: any) => {
      return {
        type: "Evento",
        // date: this.getNextUpcomingDate(singleResult.event_date),
        title: singleResult.title,
        description: singleResult.event_short_description,
        url: singleResult?.path?.alias,
        // category: singleResult?.event_tags[0]?.title,
        image: singleResult.place_image?.field_media_image
          ? {
            url: singleResult?.event_image?.field_media_image?.uri?.url,
            alternativeText: singleResult?.event_image?.field_media_image?.meta?.alt
          }
          : null
      };

    }).filter((item: any) => item !== null && item?.date != false) as Event[]; // Rimuovi eventuali oggetti null
    return { data: events, paginationInfo: response?.meta };

  }

  getRelatedTags() {
    return this.http.get(`${this.API_URL}/api/tags?filter[tag_related_tags][condition][path]=tag_related_tags.id&filter[tag_related_tags][condition][operator]=>&filter[tag_related_tags][condition][value]=0&filter[status][value]=1&fields[tag]=title,tag_body,tag_related_tags,path&sort=title&include=tag_related_tags`);
  }

  getNewsByCat(taxonomyId: string = '1', page: number = 0, limit: number = 1) {
    return this.http.get(`${this.API_URL}/api/news?filter[news_type_tid][condition][path]=news_type.drupal_internal__tid&filter[news_type_tid][condition][operator]==&filter[news_type_tid][condition][value]=${taxonomyId}&filter[status][value]=1&fields[news]=title,news_description,path,news_type,news_publication_date,news_end_date,news_image&include=news_type,news_image.field_media_image&jsonapi_include=1&page[offset]=${page}&page[limit]=${limit}`);
  }

  getDocumentByCat(taxonomyId: string = '1', page: number = 0, limit: number = 1) {
    return this.http.get(`${this.API_URL}/api/public_documents?filter[public_document_type.drupal_internal__tid][condition][path]=public_document_type.drupal_internal__tid&filter[public_document_type.drupal_internal__tid][condition][operator]==&filter[public_document_type.drupal_internal__tid][condition][value]=${taxonomyId}&filter[status][value]=1&fields[public_document]=title,public_document_body,public_document_type,public_document_short_desc,path&include=public_document_type&jsonapi_include=1&page[offset]=${page}&page[limit]=${limit}`);
  }

  getPersonByCat(taxonomyId: string = '', page: number = 0, limit: number = 1) {
    return this.http.get(`${this.API_URL}/api/persons?filter[person_type.drupal_internal__tid][condition][path]=person_type.drupal_internal__tid&filter[person_type.drupal_internal__tid][condition][operator]==&filter[person_type.drupal_internal__tid][condition][value]=${taxonomyId}&filter[status][value]=1&fields[person]=title,person_body,person_type,path,person_image&include=person_type,person_image.field_media_image&jsonapi_include=1&page[offset]=${page}&page[limit]=${limit}`);
  }
  getOrgUnitByTag(taxonomyId: string = '', page: number = 0, limit: number = 1) {
    return this.http.get(`${this.API_URL}/api/org_units?filter[org_unit_type_tid][condition][path]=org_unit_type.drupal_internal__tid&filter[org_unit_type_tid][condition][operator]==&filter[org_unit_type_tid][condition][value]=${taxonomyId}&filter[status][value]=1&fields[org_unit]=title,org_unit_short_description,path,org_unit_type,org_unit_image&include=org_unit_type,org_unit_image.field_media_image&jsonapi_include=1&page[offset]=${page}&page[limit]=${limit}`);
  }

  getEvents(startDate: string, endDate: string, title: string, taxonomyId: string) {
    startDate = this.toTimeStamp(startDate).toString();
    endDate = this.toTimeStamp(endDate).toString();
    let filters = [];

    // Conditionally add category filter
    if (taxonomyId) {
      filters.push(`filter[event_type][condition][path]=event_type.drupal_internal__tid&filter[event_type][condition][value]=${taxonomyId}`);
    }

    // Conditionally add text search filter
    if (title) {
      filters.push(`filter[title][condition][path]=title&filter[title][condition][operator]=CONTAINS&filter[title][condition][value]=${title}`);
    }

    // Date range filters
    if (startDate && endDate) {
      '?filter[event_date_value][condition][path]=event_date.value&filter[event_date_value][condition][operator]=<%3D&filter[event_date_value][condition][value]=1720569600&filter[event_date_end_value][condition][path]=event_date.end_value&filter[event_date_end_value][condition][operator]=>%3D&filter[event_date_end_value][condition][value]=1704110400'
      filters.push(`filter[event_date_value][condition][path]=event_date.value&filter[event_date_value][condition][operator]=<%3D&filter[event_date_value][condition][value]=${startDate}`);
      filters.push(`&filter[event_date_end_value][condition][path]=event_date.end_value&filter[event_date_end_value][condition][operator]=>%3D&filter[event_date_end_value][condition][value]=${startDate}`);
    }

    // Combine all parts to form the full API URL
    let apiUrl = `${this.API_URL}/api/events?${filters.join('&')}`;

    return this.http.get(apiUrl);
  }


  toTimeStamp(inputDate: Date | string, isStartDate: boolean = false) {
    //if is start date then set time to 00:00:00 else set time to 23:59:59
    const date = new Date(inputDate);
    const offset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(date.getTime() - offset);
    if (isStartDate) {
      adjustedDate.setHours(0, 0, 0, 0);
    } else {
      adjustedDate.setHours(23, 59, 59, 999);
    }
    const timestampMilliseconds = adjustedDate.getTime();
    const timestampSeconds = Math.floor(timestampMilliseconds / 1000);

    return timestampSeconds;

  }

  getSitemap(url: string = 'sitemap.xml') {
    return this.http.get(`${this.API_URL}/${url}`, { responseType: 'text' });
  }
}


