import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DynamicComponentDirective } from "../directives/dynamic-component.directive";
import { LayoutsComponent } from "./layouts.component";
import { SkeletonComponent } from "../shared/components/skeleton/skeleton.component";

@NgModule({
	declarations: [
		LayoutsComponent,
		DynamicComponentDirective,
	],
	imports: [
		SkeletonComponent,
		CommonModule, // Add this line to import CommonModule
	],
	exports: [LayoutsComponent],

})
export class LayoutsModule {

}