import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef | any;
  private activeStep: number = 0;
  private fieldset1: ElementRef | any;
  private fieldset2: ElementRef | any;
  private dataSubmitted = { stars: 0, radioSelected: '', radioSelectedValue: '', feedbackMessage: '', page: '' }
  urlMap: Record<string, string> = {};
  private storedData = JSON.parse(localStorage.getItem('feedback') ?? '[]');

  constructor(private router: Router) { }


  ngOnInit(): void {

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: any) => {
    //   this.initRating(event.urlAfterRedirects);
    // });

    // if (window.location.pathname !== '') {
    //   this.initRating(window.location.pathname);

    // }
  }

  openNextStep() {
    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.remove('d-none');

    const inputRadio = this.wrapper.nativeElement.querySelector('[name="ratingA"]:checked');
    const currentFieldset = parseInt(inputRadio.value) > 3
      ? this.fieldset1
      : this.fieldset2;
    currentFieldset.classList.remove('d-none');

    const currentStepWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep + 1}"]`);
    currentStepWrapper.classList.remove('d-none');
    currentStepWrapper.classList.add('active');

    const steps = this.wrapper.nativeElement.querySelectorAll('[data-step]');
    const currentPath = window.location.pathname === '/' ? 'homepage' : window.location.pathname;
    const activeRadio = this.wrapper.nativeElement.querySelector('[name="rating1"]:checked') ?? this.wrapper.nativeElement.querySelector('[name="rating2"]:checked');
    const feedbackMessage = this.wrapper.nativeElement.querySelector('[id="formGroupExampleInputWithHelp"]').value ?? '';
    this.activeStep = this.activeStep + 1;
    this.dataSubmitted = {
      stars: parseInt(inputRadio.value),
      radioSelected: activeRadio?.id,
      radioSelectedValue: activeRadio?.value,
      feedbackMessage: feedbackMessage,
      page: currentPath
    };
    const data = localStorage.getItem('feedback') ?? '[]';

    if (this.activeStep === steps.length) {
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      formWrapper.classList.add('d-none');
      cardRating.classList.add('d-none');
      const event = new Event('feedback-submit');
      data ? localStorage.setItem('feedback', JSON.stringify([...JSON.parse(data), this.dataSubmitted])) : localStorage.setItem('feedback', JSON.stringify([this.dataSubmitted]));
      document.dispatchEvent(event);
      this.ngOnInit();
    }
  }

  backToPrevious() {
    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.remove('d-none');

    this.activeStep = this.activeStep - 1;

    if (this.activeStep === 0) {
      formWrapper.classList.add('d-none');

      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.remove('d-none');

      this.resetFieldsets();

      this.wrapper.nativeElement.querySelector('[name="ratingA"]:checked').checked = false;
    } else {
      const previousWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep + 1}"]`);
      previousWrapper.classList.add('d-none');
      previousWrapper.classList.remove('active');

      const currentWrapper = this.wrapper.nativeElement.querySelector(`[data-step="${this.activeStep}"]`);
      currentWrapper.classList.remove('d-none');
      currentWrapper.classList.add('active');
    }
  }

  resetBlock() {
    this.closePreviousStep();

    const formWrapper = this.wrapper.nativeElement.querySelector('.form-rating');
    formWrapper.classList.add('d-none');

    this.resetFieldsets();

    this.activeStep = 0;
  }

  resetFieldsets() {
    this.fieldset1.classList.add('d-none');
    this.fieldset2.classList.add('d-none');
  }

  closePreviousStep() {
    const stepWrapper = this.wrapper.nativeElement.querySelector('[data-step].active');
    if (stepWrapper) {
      stepWrapper.classList.add('d-none');
      stepWrapper.classList.remove('active');
    }
  }

  initRating(url: string = '') {
    const currentData = JSON.parse(localStorage.getItem('feedback') ?? '[]');
    let urlMatch = false;
    let stars: number = 0;
    if(url === '/') url = 'homepage';
    currentData.forEach((element: any) => {
      if (element.page === url) {
        urlMatch = true;
        stars = element.stars;
      }
    });

    if (urlMatch) {
      const formRating = this.wrapper.nativeElement.querySelector('.form-rating')
      formRating.classList.add('d-none');
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.add('d-none');
      const cardRatingSecond = this.wrapper.nativeElement.querySelector('.cmp-rating__card-second');
      cardRatingSecond.classList.remove('d-none');
      const radios = this.wrapper.nativeElement.querySelectorAll('.rating input');

      radios.forEach((radio: HTMLInputElement) => {
        radio.checked = parseInt(radio.value) === stars;
        radio.disabled = true;
      });
      const rating = this.wrapper.nativeElement.querySelector('.rating');
      rating.classList.add('d-none');
      cardRatingSecond.appendChild(rating);
      cardRatingSecond.querySelector('.rating').classList.remove('d-none');
    } else {
      const cardRatingSecond = this.wrapper.nativeElement.querySelector('.cmp-rating__card-second');
      cardRatingSecond.classList.add('d-none');
      const cardRating = this.wrapper.nativeElement.querySelector('.cmp-rating__card-first');
      cardRating.classList.remove('d-none');
      const rating = this.wrapper.nativeElement.querySelector('.rating');
      cardRating.appendChild(rating);
      cardRating.querySelector('.rating').classList.remove('d-none');

      this.fieldset1 = this.wrapper.nativeElement.querySelector('.fieldset-rating-one');
      this.fieldset2 = this.wrapper.nativeElement.querySelector('.fieldset-rating-two');
      const radios = this.wrapper.nativeElement.querySelectorAll('.rating input');
      const btnNext = this.wrapper.nativeElement.querySelector('.btn-next');
      const btnBack = this.wrapper.nativeElement.querySelector('.btn-back');

      radios.forEach((radio: HTMLInputElement) => {
        radio.disabled = false;
        radio.checked = false;
        radio.addEventListener('change', () => {
          this.resetBlock();
          if (radio.checked) {
            this.openNextStep();
          }
        });
      });

      if (btnNext) {
        btnNext.addEventListener('click', () => {
          this.closePreviousStep();
          this.openNextStep();
        });
      }

      if (btnBack) {
        btnBack.addEventListener('click', () => {
          this.closePreviousStep();
          this.backToPrevious();
        });
      }
    }
  }

}
