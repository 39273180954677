<div class="container pt-5">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 ps-3 ps-md-0">
      <div class="main-section">
        <div class="animation skeleton-big-title"></div>
        <div class="animation skeleton-text-description"></div>
      </div>
    </div>

    <div class="card-section row g-3 g-md-5 mt-3">
      <div
        *ngFor="let card of skeletonCards"
        class="skeleton-card col-md-6 col-xl-4"
      >
        <div class="skeleton-card-container">
          <div class="animation skeleton-image"></div>
          <div class="card-text-container m-4">
            <div class="animation skeleton-text mt-2"></div>
            <div class="animation skeleton-description mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
