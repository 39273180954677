import { CommonModule } from "@angular/common";
import { Component, HostListener, OnInit, ViewChild, ɵɵsetNgModuleScope } from "@angular/core";
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { of, throwError } from "rxjs";
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { layoutMapper } from "../models/layouts.model";
import { DrupalService } from "../services/drupal.service";
import { DynamicComponentDirective } from "../directives/dynamic-component.directive";


@Component({
	selector: 'app-layouts',
	templateUrl: './layouts.component.html',
})
export class LayoutsComponent implements OnInit {
	@ViewChild(DynamicComponentDirective, { static: false }) dynamicComponent: DynamicComponentDirective | any;
	isHomePage: boolean = false;
	@HostListener('window:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.ctrlKey && event.altKey && event.key === 'o') {
			window.open(`${environment.config.backendUrl}/node/${this.internalNodeId}/edit`);
		}
	}

	loading: boolean = true;
	currentLanguage: string = '';
	currentRoute: string = '/homepage';
	internalNodeId: number = 0
	getLayoutComponent = layoutMapper

	constructor(
		private drupalService: DrupalService,
		private router: Router,
		private metaService: Meta
	) { }


	async ngOnInit() {
		this.router.events.subscribe(() => {
			this.isHomePage = (this.router.url === '/') || (this.router.url === '/homepage');
		});
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			tap((_: any) => this.loading = true),
			switchMap((event: NavigationEnd) => {
				this.currentRoute = event.url !== '/' ? event.url : '/homepage';
				this.currentLanguage = localStorage.getItem('currentLanguage') ?? 'it';

				if (event.url.includes('/pview')) {
					const newPath = event.url.replace('/pview', '');
					return this.drupalService.getPreview(newPath);
				} else if (event.url.includes('/search')) {
					return this.renderLayout('search');
				} else if (event.url.includes('/cookie-preferences')) {
					return this.renderLayout('cookie-preferences');
				}else if (event.url.includes('/sitemap')) {
					return this.renderLayout('sitemap');
				
				} else {
					return this.drupalService.getContentByPath(this.currentRoute, this.currentLanguage);
				}
			}),
			catchError(error => {
				console.error('Error handling navigation:', error);
				return of({ data: { status: false } }); // Return a neutral object to handle downstream
			})
		).subscribe({
			next: async (response) => {
				if (response) {
					await this.handleServiceResponse(response)
				}
			},
			error: (error) => { this.handleServiceError(error) },
			complete: () => {
				console.log('Navigation completed');
			}
		});
	}

	private async handleServiceError(error: any) {
		if (error) {
			await this.renderLayout('page-not-found', error);
		} else {
			console.error(error);
		}
		return throwError(() => new Error(error.message));
	}

	private async handleServiceResponse(r: { data: { type: string; drupal_internal__nid: number; }; }) {
		let response = r.data;
		const contentType = response?.type;
		const contentData = response;
		this.internalNodeId = response?.drupal_internal__nid;
		await this.renderLayout(contentType, contentData);
	}

	private async renderLayout(contentType: string, data?: any) {
		const viewContainerRef = this.dynamicComponent.viewContainerRef;
		viewContainerRef.clear();

		const componentOrModule: any = await this.getLayoutComponent(contentType);

		if (componentOrModule) {
			let componentType: any;

			if (componentOrModule.component) {
				// it's a module
				componentType = componentOrModule.component;
				ɵɵsetNgModuleScope(componentOrModule, {
					declarations: [componentType],
					imports: [CommonModule, RouterModule],
					exports: [componentType]
				});
			} else {
				// it's a standalone component
				componentType = componentOrModule;
			}

			const componentRef = viewContainerRef.createComponent(componentType);

			if (data) {
				componentRef.instance.data = data;
				this.updateMetaTags(data);
			}
			this.loading = false;
			if (componentType?.ɵcmp?.standalone) return componentType;
		}
	}

	updateMetaTags(data: any) {
		const description = this.getMetaDescription(data);
		/* Update title */
		this.metaService.updateTag({ property: 'og:title', content: `${data?.title}` });
		this.metaService.updateTag({ property: 'twitter:title', content: `${data?.title}` }, `name='twitter:title'`);

		/* Update description */
		this.metaService.updateTag({ property: 'og:description', content: `${description}` });
		this.metaService.updateTag({ property: 'twitter:description', content: `${description}` }, `name='twitter:description'`);

		/* Update url */
		this.metaService.updateTag({ property: 'og:url', content: `${window.location.origin}${data?.path?.alias}` });

	}

	getMetaDescription(data: any) {
		let description: string = "Sito ufficiale del Comune di Padova. Informazioni, servizi e novità sulla città.";
		if (data?.page_body?.value) description = data?.page_body?.value;
		else if (data?.service_short_description) description = data?.service_short_description;
		else if (data?.news_description) description = data?.news_description;
		else if (data?.event_short_description) description = data?.event_short_description;
		else if (data?.place_short_description) description = data?.place_short_description;
		else if (data?.tag_body?.value) description = data?.tag_body?.value;
		else if (data?.public_document_short_desc) description = data?.public_document_short_desc;
		else if (data?.org_unit_short_description) description = data?.org_unit_short_description;

		return description.replace(/<[^>]*>/g, '').slice(0, 150);
	}
}