import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage, IMenuLink, ISocial } from 'src/app/components/elementi-strutturali/header/header.interface';
import { DrupalService } from 'src/app/services/drupal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  selectedLanguage: string = 'it';
  constructor(
    private translate: TranslateService,
    private drupalService: DrupalService,

  ) {
    this.selectedLanguage = localStorage.getItem('currentLanguage') ?? 'it';

  }
  ngOnInit(): void {
    this.drupalService.getNavigation('it').subscribe((res: any) => {
      this.menuLinks = res;
    }, console.error)
  }

  @Input() menuLinks: IMenuLink[] = [];
  @Input() languages: ILanguage[] = [];
  @Input() socialNetworks: ISocial[] = [];
  @Input() loginLink: string = '#';
  @Input() navBrandLink: string = '#';
  @Input() navBrandTitle: string = '';
  @Input() brandTitle: string = '';
  @Input() brandLogo: string = '';
  @Input() brandTagline: string = '';

  @Output() languageChange: EventEmitter<string> = new EventEmitter<string>();

  changeLanguage(newLanguage: string) {
    this.translate.use(newLanguage);
    this.languageChange.emit(newLanguage);
  }




  // Rest of the component
}
