<ng-container *ngIf="loading">
  <!-- Skeleton for the homepage -->
  <app-skeleton *ngIf="isHomePage"></app-skeleton>

  <!-- Classic spinner for the other pages -->
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 80vh"
    *ngIf="!isHomePage"
  >
    <div class="progress-spinner progress-spinner-active spinner-lg">
      <span class="visually-hidden">Caricamento...</span>
    </div>
  </div>
</ng-container>

<!-- Contenuto dinamico -->
<ng-template dynamicComponent></ng-template>
