import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DrupalService } from 'src/app/services/drupal.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent {
  drupalService = inject(DrupalService);
  router = inject(Router);
  submitted: boolean = false;
  searchText: string = '';

  onSubmit(textSearched: string) {
    this.submitted = true;
    this.drupalService.searchResults.set([]);
    //this.router.navigate(['/search'], { queryParams: { q: textSearched } });
    window.location.href = `/search?q=${textSearched}`;
  }
}
