<footer class="it-footer" id="footer">
  <div class="it-footer-main">
    <div class="container">
      <div class="row">
        <div class="col-12 footer-items-wrapper logo-wrapper">
          <img class="ue-logo ls-is-cached lazyloaded" title="Finanziato dall'Unione Europea"
            alt="Finanziato dall'Unione Europea" width="178" height="56"
            src="../../../../assets/images/logo-eu-inverted.svg">
          <div class="it-brand-wrapper"><a href="/" title="Comune di Padova"> <img class="icon" title="Comune di Padova"
                alt="Comune di Padova"
                [src]="data?.field_logo?.uri?.url ? data?.field_logo?.uri?.url : '../../../../assets/images/logo.svg'"
                style="width: auto !important;">
              <div class="it-brand-text">
                <div class="it-brand-title">Comune Padova</div>
              </div>
            </a></div>
          <!-- loop - immagini -->
          <ng-container *ngIf="data?.field_logos && data?.field_logos?.length > 0">
            <ng-container *ngFor="let logo of data.field_logos">
              <div class="it-brand-wrapper">
                <a [href]="getUrlFromAlt(logo.meta?.alt)" [title]="logo.meta?.title" target="_blank">
                  <img class="icon" [alt]="logo.meta?.title" [src]="logo.uri?.url" style="width: auto !important;">
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 footer-items-wrapper">
          <h3 class="footer-heading-title"> Amministrazione
          </h3>
          <ul class="footer-list">
            <ng-container *ngFor="let nav of amministrazione[0]?.below">
              <li>
                <a [href]="nav.alias" [title]="'Vai alla pagina:' + nav.title">{{nav.title}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="col-md-6 footer-items-wrapper">
          <h3 class="footer-heading-title"> Servizi
          </h3>
          <div class="row">
            <div class="col-md-6">
              <ul class="footer-list">
                <ng-container *ngFor="let nav of servizi[0]?.below">
                  <li>
                    <a [href]="nav.alias" [title]="'Vai alla pagina:' + nav.title">{{nav.title}}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="footer-list">
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 footer-items-wrapper">
          <h3 class="footer-heading-title"> Novità
          </h3>
          <ul class="footer-list">
            <ng-container *ngFor="let nav of novita[0]?.below">
              <li>
                <a [href]="nav.alias" [title]="'Vai alla pagina:' + nav.title">{{nav.title}}</a>
              </li>
            </ng-container>
          </ul>
          <h3 class="footer-heading-title"> Vivere il comune
          </h3>
          <ul class="footer-list">
            <ng-container *ngFor="let nav of vivere[0]?.below">
              <li>
                <a [href]="nav.alias" [title]="'Vai alla pagina:' + nav.title"
                  (click)="goToPage($event,nav.alias)">{{nav.title}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="col-md-9 mt-md-4 footer-items-wrapper">
          <h3 class="footer-heading-title">Contatti</h3>
          <div class="row">
            <ng-container *ngIf="data?.field_first_column?.processed">
              <div class="col-md-4">
                <div class="footer-info" [innerHTML]="data?.field_first_column?.processed || '' | safeHtml">
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="data?.field_second_column?.processed">
              <div class="col-md-4">
                <div class="footer-info" [innerHTML]="data?.field_second_column?.processed || '' | safeHtml">
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="data?.field_third_column?.processed">
              <div class="col-md-4">
                <div class="footer-info" [innerHTML]="data?.field_third_column?.processed || '' | safeHtml">
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-3 mt-md-4 footer-items-wrapper">
          <h3 class="footer-heading-title">Seguici su</h3>
          <ul class="list-inline text-start social">
            <li class="list-inline-item mb-3">
              <a href="https://www.facebook.com/Comune.Padova" target="_blank" class="" data-focus-mouse="false"><svg
                  class="icon icon-sm icon-white align-top" aria-label="Facebook">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-facebook"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="https://www.instagram.com/comunepadova/" target="_blank"><svg
                  class="icon icon-sm icon-white align-top" aria-label="Instagram">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-instagram"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="https://www.threads.net/@comunepadova" target="_blank"><svg
                  class="icon icon-sm icon-white align-top" aria-label="Threds">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-threads"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="https://x.com/comunepadova" target="_blank"><svg class="icon icon-sm icon-white align-top"
                  aria-label="X">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-twitter"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="https://www.linkedin.com/company/comune-di-padova/" target="_blank"><svg
                  class="icon icon-sm icon-white align-top" aria-label="Linkedin">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-linkedin"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="https://www.youtube.com/c/ComunediPadovaOfficial" target="_blank"><svg
                  class="icon icon-sm icon-white align-top" aria-label="Youtube">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-youtube"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="/podcasts" target="_blank"><svg class="icon icon-sm icon-white align-top" aria-label="Podcast">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-file-audio"></use>
                </svg>
              </a>
            </li>
            <li class="list-inline-item mb-3">
              <a href="/informazione/servizio-rss-di-padovanet" target="_blank"><svg
                  class="icon icon-sm icon-white align-top" aria-label="RSS">
                  <use href="/bootstrap-italia/dist/svg/sprites.svg#it-rss"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 footer-items-wrapper">
          <div class="footer-bottom">
            <a href="/cookie-preferences" class="ccb__edit">Personalizza i cookie</a>
            <a href="/sitemap">Mappa sito</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a aria-hidden="true" tabindex="-1" data-bs-toggle="backtotop" class="back-to-top" id="example">
    <svg class="icon icon-light" aria-label="Torna a inizio pagina">
      <use href="/bootstrap-italia/dist/svg/sprites.svg#it-arrow-up"></use>
    </svg>
  </a>
</footer>