// Map content type to components using a switch case
export const layoutMapper = (contentType: any) => {
  switch (contentType) {
    case 'page':
      return import('../layouts/page-layout/page-layout.module').then((m) => m.PageLayoutModule);
    case 'page-not-found':
      return import('../layouts/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule)
    case 'faq':
      return import('../layouts/faq-layout/faq-layout.module').then((m) => m.FaqLayoutModule);
    case 'tag':
      return import('../layouts/tag-layout/tag-layout.module').then((m) => m.TagLayoutModule);
    case 'news':
      return import('../layouts/news-layout/news-layout.module').then((m) => m.NewsLayoutModule);
    case 'footer':
      return import('../components/elementi-strutturali/footer/footer.module').then((m) => m.FooterModule);
    case 'org_unit':
      return import('../layouts/org-unit-layout/org-unit-layout.module').then((m) => m.OrgUnitLayoutModule);
    case 'event':
      return import('../layouts/events-layout/events-layout.module').then((m) => m.EventsLayoutModule);
    case 'place':
      return import('../layouts/place-layout/place-layout.module').then((m) => m.PlaceLayoutModule);
    case 'search':
      return import('../layouts/search-results-layout/search-results.module').then((m) => m.SearchResultsModule);
    case 'information':
      return import('../layouts/information-layout/information-layout.module').then((m) => m.InformationLayoutModule);
    case 'person':
      return import('../layouts/person-layout/person-layout.module').then((m) => m.PersonLayoutModule);
    case 'public_document':
      return import('../layouts/public-document-layout/public-document-layout.module').then((m) => m.PublicDocumentLayoutModule);
    case 'service':
      return import('../layouts/service-layout/service-layout.module').then((m) => m.ServiceLayoutModule);
    case 'cookie-preferences':
      return import('../layouts/cookie-preferences/cookie-preferences.component').then((m) => m.CookiePreferencesComponent);
    case 'sitemap':
      return import('../components/elementi-strutturali/sitemap/sitemap.module').then((m) => m.SitemapLayoutModule);
    // Add more cases as needed
    default:
      return import('../layouts/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule)
  }
}
