<section
  *ngIf="!cookieService.checkCookie('cookie_consent')"
  class="cookiebar fade pb-4"
  aria-label="Gestione dei cookies"
>
  <p>
    Questo sito utilizza cookie tecnici, analytics e di terze parti.
    <br />Proseguendo nella navigazione accetti l’utilizzo dei cookie.
  </p>
  <div class="cookiebar-buttons pb-3 pb-md-0">
    <a [routerLink]="['/cookie-preferences']" class="cookiebar-btn"
      >Preferenze<span class="visually-hidden">cookies</span></a
    >
    <button
      class="cookiebar-btn cookiebar-confirm"
      (click)="acceptAllCookies()"
    >
      Accetto<span class="visually-hidden"> i cookies</span>
    </button>
  </div>
</section>
