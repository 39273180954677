import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { CookieService } from 'src/app/services/cookie.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-cookie-banner',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent {
  cookieService = inject(CookieService);

  acceptAllCookies() {
    this.cookieService.consent.categories.multimedia.wanted = true;
    this.cookieService.setCookie('cookie_consent', this.cookieService.consent, 365);
  }
}
