import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.body.is_redirected && event.body.status == 301) {
            console.log('Redirecting to:', event.headers.get('Location'));
            const redirectUrl = event.body.redirect
            if (redirectUrl) {
              window.location = redirectUrl;
            }
          }
        }
      })
    );
  }
}
