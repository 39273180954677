<app-cookie-banner></app-cookie-banner>
<app-header
  [languages]="languages"
  [loginLink]="loginLink"
  [navBrandLink]="navBrandLink"
  [navBrandTitle]="navBrandTitle"
  [brandTitle]="brandTitle"
  [brandTagline]="brandTagline"
  [socialNetworks]="socialNetworks"
  (languageChange)="onLanguageChange($event)"
></app-header>
<main>
  <app-layouts></app-layouts>
</main>
<app-rating *ngIf="isLoaded"></app-rating>
<app-footer *ngIf="isLoaded"></app-footer>
<app-search-modal></app-search-modal>
