import { Injectable } from '@angular/core';
interface CookieConsent {
  version: number;
  categories: {
    necessary: { wanted: boolean };
    multimedia: { wanted: boolean };
  };
  services: string[];
}
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  consent: CookieConsent = {
    version: 1,
    categories: {
      necessary: { wanted: true },
      multimedia: { wanted: true }
    },
    services: ["multimedia"]
  };

  constructor() { }

  // Imposta un cookie senza codifica URL
  setCookie(name: string, value: string | object, days?: number): void {
    let cookieValue: string;
    if (typeof value === 'object') {
      cookieValue = JSON.stringify(value);
    } else {
      cookieValue = value;
    }

    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }

    // Usa il valore senza codifica
    document.cookie = `${name}=${cookieValue}${expires}; path=/`;
  }

  // Ottiene il valore di un cookie senza decodifica
  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Ottiene il valore di un cookie come oggetto
  getCookieObject<T>(name: string): T | null {
    const cookieValue = this.getCookie(name);
    if (cookieValue) {
      try {
        return JSON.parse(cookieValue) as T;
      } catch (e) {
        console.error('Errore nel parsing del cookie', e);
        return null;
      }
    }
    return null;
  }

  // Elimina un cookie
  deleteCookie(name: string): void {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  // Controlla se un cookie esiste
  checkCookie(name: string): boolean {
    return this.getCookie(name) !== null;
  }

  // Ottiene tutti i cookie come oggetto
  getAllCookies(): { [key: string]: string } {
    const pairs = document.cookie.split(";");
    const cookies: { [key: string]: string } = {};
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split("=");
      cookies[(pair[0] + '').trim()] = decodeURIComponent(pair[1].trim());
    }
    return cookies;
  }

  // Imposta un cookie con opzioni avanzate
  setCookieAdvanced(name: string, value: string | object, options: {
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'strict' | 'lax' | 'none'
  } = {}): void {
    let cookieValue: string;
    if (typeof value === 'object') {
      cookieValue = JSON.stringify(value);
    } else {
      cookieValue = value;
    }

    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(cookieValue)}`;

    if (options.expires) {
      if (typeof options.expires === 'number') {
        const date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
        options.expires = date;
      }
      cookieString += `; expires=${options.expires.toUTCString()}`;
    }

    if (options.path) cookieString += `; path=${options.path}`;
    if (options.domain) cookieString += `; domain=${options.domain}`;
    if (options.secure) cookieString += '; secure';
    if (options.sameSite) cookieString += `; samesite=${options.sameSite}`;

    document.cookie = cookieString;
  }

  // Pulisce tutti i cookie
  clearAllCookies(): void {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    }
  }
}