import { NgModule } from '@angular/core';
import { FileSizePipe } from 'src/app/pipes/filesize.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/sanitize.pipe';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';



@NgModule({
  declarations: [SafeHtmlPipe, FileSizePipe, TruncatePipe],
  exports: [SafeHtmlPipe, FileSizePipe, TruncatePipe]
})
export class SharedModuleModule { }
