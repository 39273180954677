<div
  class="modal fade search-modal"
  tabindex="-1"
  role="dialog"
  id="search-modal"
  aria-modal="true"
>
  <div class="modal-dialog modal-lg modal-fullscreen" role="document">
    <div class="modal-content perfect-scrollbar">
      <div class="modal-body">
        <form (ngSubmit)="onSubmit(searchText)" novalidate>
          <div class="modal-title">
            <button
              class="search-link d-md-none"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#search-modal"
              aria-label="Chiudi"
              title="Chiudi"
            >
              <svg class="icon icon-md" aria-label="Chiudi">
                <use
                  href="bootstrap-italia/dist/svg/sprites.svg#it-arrow-left"
                ></use>
              </svg>
            </button>
            <h2>Cerca</h2>
            <button
              class="search-link d-none d-md-block"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#search-modal"
              aria-label="Chiudi"
              title="Chiudi"
            >
              <svg class="icon icon-md" aria-label="Chiudi">
                <use
                  href="bootstrap-italia/dist/svg/sprites.svg#it-close-big"
                ></use>
              </svg>
            </button>
          </div>
          <div class="form-group autocomplete-wrapper">
            <label for="autocomplete-two" class="visually-hidden"
              >Cerca in tutto il contenuto</label
            >
            <input
              [(ngModel)]="searchText"
              type="search"
              class="autocomplete ps-5"
              placeholder="Cerca nel sito"
              id="autocomplete-two"
              name="searchText"
              data-bs-autocomplete="[]"
            />
            <span class="autocomplete-icon" aria-hidden="true">
              <svg class="icon" aria-label="Cerca nel sito">
                <use
                  href="bootstrap-italia/dist/svg/sprites.svg#it-search"
                ></use>
              </svg>
            </span>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!searchText || searchText.trim().length < 3"
            >
              <span>Cerca</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
