import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrupalService } from 'src/app/services/drupal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  amministrazione: any[] = []
  servizi: any[] = []
  novita: any[] = []
  vivere: any[] = []
  data: any


  constructor(
    private drupalService: DrupalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.drupalService.getConfig().then((response: any) => {
      this.data = response.data[0]
    })
    this.drupalService.getNavigation().subscribe((response: any) => {
      this.amministrazione = response.filter((item: any) => {
        return item.alias === 'amministrazione'
      })
      this.servizi = response.filter((item: any) => {
        return item.alias === 'servizi'
      })
      this.novita = response.filter((item: any) => {
        return item.alias === 'novita'
      })
      this.vivere = response.filter((item: any) => {
        return item.alias === 'vivere-il-comune'
      })
    })
  }

  goToPage($event: MouseEvent, page: string) {
    $event.preventDefault()
    if (page.includes('/eventi')) {
      this.router.navigate([page], { state: { isFilterPage: true, type: 'event' } })
    } else {
      this.router.navigate([page])
    }

  }

  getUrlFromAlt(url: string = '#'){
    if(/(http(s?)):\/\//i.test(url)) {
      return url
    }else {
      return '#'
    }

  }
}
