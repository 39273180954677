<div class="container px-4 my-4">
  <div class="alert alert-warning">
    <h2>Pagina non trovata</h2>
    <p>I motivi possibili di questo errore possono essere i seguenti:</p>
    <ul>
      <li>Il nome della pagina è stato modificato.</li>
      <li>La pagina non è più presente sul sito web.</li>
    </ul>
  </div>
</div>
